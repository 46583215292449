
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import {DrawerComponent} from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "ToolsTrainingModal",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") !== "null"
          ? VueCookieNext.getCookie("_ia_partner_id")
          : 0,
      created_by: VueCookieNext.getCookie("_user_id"),
      training_title: "",
      delivery_method: "",
      total_male_participants: "",
      total_female_participants: "",
      date: "",
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };
          }
        }
    );

    const schema = object().shape({
      training_title: string().required("Training is required"),
    });

    const errors = ref({});

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        await schema.validate(formData.value, { abortEarly: false });


        ApiService.post(
            apiEndpoint.data().VUE_APP_TOOLS_TRAINING_STORE,
            formData.value
        )
            .then((response) => {
              console.log(response.data);
              if (response.data.status == "success") {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.data,
                  type: "success",
                });
                DrawerComponent.hideAll();
                bus.emit("loadData", true);
              } else {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.msg,
                  type: "error",
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });

        formData.value = {
          id: "",
          updated_by: "",
          ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") !== "null"
              ? VueCookieNext.getCookie("_ia_partner_id")
              : 0,
          created_by: VueCookieNext.getCookie("_user_id"),
          training_title: "",
          delivery_method: "",
          total_male_participants: "",
          total_female_participants: "",
          date: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    return {
      formData,
      errors,
      save,
    };
  },
});
