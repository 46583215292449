
import { defineComponent, ref, onMounted, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";
import { VueCookieNext } from "vue-cookie-next";
import ToolsTrainingModal from "@/components/modals/forms/enterprise/ToolsTrainingModal.vue";
import { formatDate } from "@/core/helpers/genericHelper.ts";

export default defineComponent({
  name: "toolsTrainingList",
  components: {
    ToolsTrainingModal,
  },
  setup() {
    const { bus } = useBus();
    const editData = ref(null);
    const exportType = ref(null);
    const list = ref([]);

    // pagination
    const currentPage = ref(1);
    const perPageOptions = [10, 20, 50];
    const selectedPerPage = ref(perPageOptions[0]);
    const totalRows = ref(0);

    const handleSizeChange = (val) => {
      selectedPerPage.value = val;
      loadData();
    };

    const handleCurrentChange = (val) => {
      currentPage.value = val;
      loadData();
    };

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (data) => {
      editData.value = data;
    };

    const show = (item) => {
      // Add logic for showing item
    };

    const destroy = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_TOOLS_TRAINING_DELETE,
              {
                id: id,
              }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_TOOLS_TRAINING_LIST,
          {
            page: currentPage.value,
            per_page: selectedPerPage.value,
          }
        );
        list.value = response.data.data.data;
        totalRows.value = response.data.data.total;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const exportChange = async () => {
      if (exportType.value == "excel") {
        exportExcel();
      }
    };

    const exportExcel = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_ACTIVITY_PROGRESS_EXPORT,
          {}
        );

        if (response.data.status === "success" && response.data.url) {
          // Create a link element
          const downloadLink = document.createElement("a");
          downloadLink.href =
            apiEndpoint.data().BEE_FILE_URL + response.data.url;
          console.log(downloadLink.href);
          downloadLink.target = "_blank"; // Open in a new tab/window
          downloadLink.download = "monthly_reporting.xlsx"; // Specify the desired file name

          // Append the link to the document
          document.body.appendChild(downloadLink);

          // Trigger a click event on the link to start the download
          downloadLink.click();

          // Remove the link from the document
          document.body.removeChild(downloadLink);
        } else {
          console.error("Invalid response for export:", response);
        }
      } catch (error) {
        console.error("Error exporting Excel:", error);
      }
    };

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return {
      editData,
      list,
      create,
      edit,
      show,
      destroy,
      loadData,
      exportChange,
      exportType,
      formatDate,
      currentPage,
      perPageOptions,
      selectedPerPage,
      totalRows,
      handleSizeChange,
      handleCurrentChange,
    };
  },
});
